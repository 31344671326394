import * as React from 'react';
import { Link, graphql, PageProps } from 'gatsby';
// import { StaticImage } from 'gatsby-plugin-image';

import PostLayout from '../components/postLayout';
import Seo from '../components/seo';
import { NotFoundPageQueryQuery } from '../../types/graphql-types';

const NotFoundPage: React.FC<PageProps<NotFoundPageQueryQuery>> = ({
  data,
  location,
}) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <PostLayout location={location} title={siteTitle}>
      <Seo title="ページが見つかりません" />
      <article itemScope itemType="http://schema.org/Article">
        <header>
          {/* <!--Title--> */}
          <div className="text-center pt-16 md:pt-32">
            <h1 className="font-bold break-normal text-3xl md:text-5xl">
              ページが見つかりませんでした
            </h1>
          </div>
          {/* <!--image--> */}
          <div
            className="container w-full max-w-6xl mx-auto bg-white bg-cover mt-8 rounded"
            style={{
              backgroundImage:
                "url('https://source.unsplash.com/collection/1118905/')",
              height: '20vh',
            }}
          />
        </header>

        {/* <!--Container--> */}
        <div className="container max-w-5xl mx-auto -mt-32">
          <div className="mx-0 sm:mx-6">
            <div className="bg-white w-full p-8 md:p-24 text-xl md:text-2xl text-gray-800 leading-normal">
              {/* <!--/ Post Content--> */}
              <section>
                <div className="mt-2 text-gray-800 markdown">
                  <p>トップページからお探しのページが見つかるかもしれません</p>
                  <Link to="/">トップページにすすむ</Link>
                </div>
              </section>
            </div>
          </div>
        </div>
      </article>
    </PostLayout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query NotFoundPageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
